import MainView from '../views/MainView'
import Home from '../views/Home'
import Auth from '../views/Auth'

export default [
  {
    path: '/',
    name: 'Site',
    component: MainView,
    redirect: { name: 'Home' },
    meta: {
      auth: true
    },
    children: [
      {
        path: 'home',
        name: 'Home',
        component: Home
      }
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: {
      not_logged: true
    }
  }
]
