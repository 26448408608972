<template>
  <el-dialog
    title="Add Expense"
    :visible="true"
    width="40%"
    :before-close="close">
    <el-form ref="form" :model="form" label-width="0">
      <el-form-item>
        <el-input
          ref="note"
          v-model="form.name"
          placeholder="Enter note" />
      </el-form-item>

      <el-form-item>
        <el-input
          v-model.number="form.expense"
          placeholder="Enter expense" />
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">Cancel</el-button>
      <el-button type="primary" @click="save()">Add Expense</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      form: {
        name: null,
        expense: null
      }
    }
  },

  methods: {
    async save () {
      if (this.form.expense) {
        const loading = this.$loading()
        try {
          await this.$store.dispatch('expenses/create', {
            ...this.form,
            category_id: this.category.id
          })
          this.$message.success('Expense added successfully.')
          this.close()
        } finally {
          loading.close()
        }
      }
    },

    close () {
      this.$emit('close')
    }
  },

  mounted () {
    this.$nextTick(() => this.$refs.note?.focus())
  }
}
</script>

<style lang="scss" scoped>
</style>
