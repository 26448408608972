export default [
  'accessibility-outline',
  'add-outline',
  'add-circle-outline',
  'airplane-outline',
  'alarm-outline',
  'albums-outline',
  'alert-outline',
  'alert-circle-outline',
  'american-football-outline',
  'analytics-outline',
  'aperture-outline',
  'apps-outline',
  'archive-outline',
  'arrow-back-outline',
  'arrow-back-circle-outline',
  'arrow-down-outline',
  'arrow-down-circle-outline',
  'arrow-forward-outline',
  'arrow-forward-circle-outline',
  'arrow-redo-outline',
  'arrow-redo-circle-outline',
  'arrow-undo-outline',
  'arrow-undo-circle-outline',
  'arrow-up-outline',
  'arrow-up-circle-outline',
  'at-outline',
  'at-circle-outline',
  'attach-outline',
  'backspace-outline',
  'bag-outline',
  'bag-add-outline',
  'bag-check-outline',
  'bag-handle-outline',
  'bag-remove-outline',
  'balloon-outline',
  'ban-outline',
  'bandage-outline',
  'bar-chart-outline',
  'barbell-outline',
  'barcode-outline',
  'baseball-outline',
  'basket-outline',
  'basketball-outline',
  'battery-charging-outline',
  'battery-dead-outline',
  'battery-full-outline',
  'battery-half-outline',
  'beaker-outline',
  'bed-outline',
  'beer-outline',
  'bicycle-outline',
  'bluetooth-outline',
  'boat-outline',
  'body-outline',
  'bonfire-outline',
  'book-outline',
  'bookmark-outline',
  'bookmarks-outline',
  'bowling-ball-outline',
  'briefcase-outline',
  'browsers-outline',
  'brush-outline',
  'bug-outline',
  'build-outline',
  'bulb-outline',
  'bus-outline',
  'business-outline',
  'cafe-outline',
  'calculator-outline',
  'calendar-outline',
  'calendar-clear-outline',
  'calendar-number-outline',
  'call-outline',
  'camera-outline',
  'camera-reverse-outline',
  'car-outline',
  'car-sport-outline',
  'card-outline',
  'caret-back-outline',
  'caret-back-circle-outline',
  'caret-down-outline',
  'caret-down-circle-outline',
  'caret-forward-outline',
  'caret-forward-circle-outline',
  'caret-up-outline',
  'caret-up-circle-outline',
  'cart-outline',
  'cash-outline',
  'cellular-outline',
  'chatbox-outline',
  'chatbox-ellipses-outline',
  'chatbubble-outline',
  'chatbubble-ellipses-outline',
  'chatbubbles-outline',
  'checkbox-outline',
  'checkmark-outline',
  'checkmark-circle-outline',
  'checkmark-done-outline',
  'checkmark-done-circle-outline',
  'chevron-back-outline',
  'chevron-back-circle-outline',
  'chevron-down-outline',
  'chevron-down-circle-outline',
  'chevron-forward-outline',
  'chevron-forward-circle-outline',
  'chevron-up-outline',
  'chevron-up-circle-outline',
  'clipboard-outline',
  'close-outline',
  'close-circle-outline',
  'cloud-outline',
  'cloud-circle-outline',
  'cloud-done-outline',
  'cloud-download-outline',
  'cloud-offline-outline',
  'cloud-upload-outline',
  'cloudy-outline',
  'cloudy-night-outline',
  'code-outline',
  'code-download-outline',
  'code-slash-outline',
  'code-working-outline',
  'cog-outline',
  'color-fill-outline',
  'color-filter-outline',
  'color-palette-outline',
  'color-wand-outline',
  'compass-outline',
  'construct-outline',
  'contract-outline',
  'contrast-outline',
  'copy-outline',
  'create-outline',
  'crop-outline',
  'cube-outline',
  'cut-outline',
  'desktop-outline',
  'diamond-outline',
  'dice-outline',
  'disc-outline',
  'document-outline',
  'document-attach-outline',
  'document-lock-outline',
  'document-text-outline',
  'documents-outline',
  'download-outline',
  'duplicate-outline',
  'ear-outline',
  'earth-outline',
  'easel-outline',
  'egg-outline',
  'ellipse-outline',
  'ellipsis-horizontal-outline',
  'ellipsis-horizontal-circle-outline',
  'ellipsis-vertical-outline',
  'ellipsis-vertical-circle-outline',
  'enter-outline',
  'exit-outline',
  'expand-outline',
  'extension-puzzle-outline',
  'eye-outline',
  'eye-off-outline',
  'eyedrop-outline',
  'fast-food-outline',
  'female-outline',
  'file-tray-outline',
  'file-tray-full-outline',
  'file-tray-stacked-outline',
  'film-outline',
  'filter-outline',
  'filter-circle-outline',
  'finger-print-outline',
  'fish-outline',
  'fitness-outline',
  'flag-outline',
  'flame-outline',
  'flash-outline',
  'flash-off-outline',
  'flashlight-outline',
  'flask-outline',
  'flower-outline',
  'folder-outline',
  'folder-open-outline',
  'football-outline',
  'footsteps-outline',
  'funnel-outline',
  'game-controller-outline',
  'gift-outline',
  'git-branch-outline',
  'git-commit-outline',
  'git-compare-outline',
  'git-merge-outline',
  'git-network-outline',
  'git-pull-request-outline',
  'glasses-outline',
  'globe-outline',
  'golf-outline',
  'grid-outline',
  'hammer-outline',
  'hand-left-outline',
  'hand-right-outline',
  'happy-outline',
  'hardware-chip-outline',
  'headset-outline',
  'heart-outline',
  'heart-circle-outline',
  'heart-dislike-outline',
  'heart-dislike-circle-outline',
  'heart-half-outline',
  'help-outline',
  'help-buoy-outline',
  'help-circle-outline',
  'home-outline',
  'hourglass-outline',
  'ice-cream-outline',
  'id-card-outline',
  'image-outline',
  'images-outline',
  'infinite-outline',
  'information-outline',
  'information-circle-outline',
  'invert-mode-outline',
  'journal-outline',
  'key-outline',
  'keypad-outline',
  'language-outline',
  'laptop-outline',
  'layers-outline',
  'leaf-outline',
  'library-outline',
  'link-outline',
  'list-outline',
  'list-circle-outline',
  'locate-outline',
  'location-outline',
  'lock-closed-outline',
  'lock-open-outline',
  'log-in-outline',
  'log-out-outline',
  'magnet-outline',
  'mail-outline',
  'mail-open-outline',
  'mail-unread-outline',
  'male-outline',
  'male-female-outline',
  'man-outline',
  'map-outline',
  'medal-outline',
  'medical-outline',
  'medkit-outline',
  'megaphone-outline',
  'menu-outline',
  'mic-outline',
  'mic-circle-outline',
  'mic-off-outline',
  'mic-off-circle-outline',
  'moon-outline',
  'move-outline',
  'musical-note-outline',
  'musical-notes-outline',
  'navigate-outline',
  'navigate-circle-outline',
  'newspaper-outline',
  'notifications-outline',
  'notifications-circle-outline',
  'notifications-off-outline',
  'notifications-off-circle-outline',
  'nuclear-outline',
  'nutrition-outline',
  'open-outline',
  'options-outline',
  'paper-plane-outline',
  'partly-sunny-outline',
  'pause-outline',
  'pause-circle-outline',
  'paw-outline',
  'pencil-outline',
  'people-outline',
  'people-circle-outline',
  'person-outline',
  'person-add-outline',
  'person-circle-outline',
  'person-remove-outline',
  'phone-landscape-outline',
  'phone-portrait-outline',
  'pie-chart-outline',
  'pin-outline',
  'pint-outline',
  'pizza-outline',
  'planet-outline',
  'play-outline',
  'play-back-outline',
  'play-back-circle-outline',
  'play-circle-outline',
  'play-forward-outline',
  'play-forward-circle-outline',
  'play-skip-back-outline',
  'play-skip-back-circle-outline',
  'play-skip-forward-outline',
  'play-skip-forward-circle-outline',
  'podium-outline',
  'power-outline',
  'pricetag-outline',
  'pricetags-outline',
  'print-outline',
  'prism-outline',
  'pulse-outline',
  'push-outline',
  'qr-code-outline',
  'radio-outline',
  'radio-button-off-outline',
  'radio-button-on-outline',
  'rainy-outline',
  'reader-outline',
  'receipt-outline',
  'recording-outline',
  'refresh-outline',
  'refresh-circle-outline',
  'reload-outline',
  'reload-circle-outline',
  'remove-outline',
  'remove-circle-outline',
  'reorder-four-outline',
  'reorder-three-outline',
  'reorder-two-outline',
  'repeat-outline',
  'resize-outline',
  'restaurant-outline',
  'return-down-back-outline',
  'return-down-forward-outline',
  'return-up-back-outline',
  'return-up-forward-outline',
  'ribbon-outline',
  'rocket-outline',
  'rose-outline',
  'sad-outline',
  'save-outline',
  'scale-outline',
  'scan-outline',
  'scan-circle-outline',
  'school-outline',
  'search-outline',
  'search-circle-outline',
  'send-outline',
  'server-outline',
  'settings-outline',
  'shapes-outline',
  'share-outline',
  'share-social-outline',
  'shield-outline',
  'shield-checkmark-outline',
  'shield-half-outline',
  'shirt-outline',
  'shuffle-outline',
  'skull-outline',
  'snow-outline',
  'sparkles-outline',
  'speedometer-outline',
  'square-outline',
  'star-outline',
  'star-half-outline',
  'stats-chart-outline',
  'stop-outline',
  'stop-circle-outline',
  'stopwatch-outline',
  'storefront-outline',
  'subway-outline',
  'sunny-outline',
  'swap-horizontal-outline',
  'swap-vertical-outline',
  'sync-outline',
  'sync-circle-outline',
  'tablet-landscape-outline',
  'tablet-portrait-outline',
  'telescope-outline',
  'tennisball-outline',
  'terminal-outline',
  'text-outline',
  'thermometer-outline',
  'thumbs-down-outline',
  'thumbs-up-outline',
  'thunderstorm-outline',
  'ticket-outline',
  'time-outline',
  'timer-outline',
  'today-outline',
  'toggle-outline',
  'trail-sign-outline',
  'train-outline',
  'transgender-outline',
  'trash-outline',
  'trash-bin-outline',
  'trending-down-outline',
  'trending-up-outline',
  'triangle-outline',
  'trophy-outline',
  'tv-outline',
  'umbrella-outline',
  'unlink-outline',
  'videocam-outline',
  'videocam-off-outline',
  'volume-high-outline',
  'volume-low-outline',
  'volume-medium-outline',
  'volume-mute-outline',
  'volume-off-outline',
  'walk-outline',
  'wallet-outline',
  'warning-outline',
  'watch-outline',
  'water-outline',
  'wifi-outline',
  'wine-outline',
  'woman-outline'
]
