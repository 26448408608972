<template>
  <el-dialog
    title="Add Category"
    :visible="true"
    width="40%"
    :before-close="close">
    <el-input placeholder="Enter category name" v-model="form.name" />

    <div class="icons">
      <div
        v-for="icon in icons"
        :key="icon"
        class="icon"
        :class="{ selected: form.icon === icon }">
        <ion-icon
          :name="icon"
          size="large"
          @click="form.icon = icon" />
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close()">Cancel</el-button>
      <el-button type="primary" @click="save()">Add Category</el-button>
    </span>
  </el-dialog>
</template>

<script>
import icons from '@/common/icons'

export default {
  props: {
  },

  data () {
    return {
      form: {
        name: null,
        icon: icons[0]
      },

      icons
    }
  },

  methods: {
    async save () {
      if (this.form.name && this.form.icon) {
        const loading = this.$loading()
        try {
          await this.$store.dispatch('categories/create', this.form)
          this.$message.success('Category added successfully.')
          this.close()
        } finally {
          loading.close()
        }
      }
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.icons {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 20px;
  .icon {
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 4px;
    border-radius: 3px;
    &:hover, &:focus {
      border-color: rgba(0, 0, 0, .2);
      cursor: pointer;
    }
    &.selected {
      background-color: $primary;
    }
  }
}
</style>
