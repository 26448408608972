<template>
  <header>
    <el-button
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="addCategory()">Add Category</el-button>

    <el-dropdown trigger="click" @command="onClick($event)">
      <span class="el-dropdown-link profile">
        k1dalica
        <div class="avatar">
          <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
        </div>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="manageProfile">Manage Profile</el-dropdown-item>
        <el-dropdown-item command="logout">Logout</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <manage-category
      v-if="manageCategory"
      @close="manageCategory = false" />
  </header>
</template>

<script>
import ManageCategory from './ManageCategory'

export default {
  components: {
    ManageCategory
  },

  data () {
    return {
      manageCategory: false
    }
  },

  methods: {
    addCategory () {
      this.manageCategory = true
    },

    onClick (action) {
      this[action]()
    },

    logout () {
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'Auth' })
    },

    manageProfile () {

    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    .profile {
      cursor: pointer;
      font-weight: bold;
      color: $dark;
      &:hover {
        color: $primary-dark;
      }
      .avatar {
        display: inline-block;
        vertical-align: middle;
        margin-left: 15px;
      }
    }
  }
</style>
