<template>
  <div class="width page">
    <el-row :gutter="20" class="width">
      <el-col :span="12">
        <el-card class="category">
          <h1>Sign In</h1>
          <el-form ref="form" :model="form" label-width="0">
            <el-form-item>
              <el-input
                v-model="form.username"
                placeholder="Enter email" />
            </el-form-item>

            <el-form-item>
              <el-input
                ref="password"
                v-model="form.password"
                placeholder="Enter password"
                show-password />
            </el-form-item>
          </el-form>

          <el-button type="primary" @click="login()">Sign In</el-button>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="category">
          <h1>Sign Up</h1>

          <el-form ref="form" :model="registration" label-width="0">
            <el-form-item>
              <el-input
                ref="note"
                v-model="registration.name"
                placeholder="Enter name" />
            </el-form-item>

            <el-form-item>
              <el-input
                ref="note"
                v-model="registration.email"
                placeholder="Enter email" />
            </el-form-item>

            <el-form-item>
              <el-input
                v-model="registration.password"
                placeholder="Enter password"
                show-password />
            </el-form-item>
          </el-form>

          <el-button type="primary" @click="register()">Sign Up</el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import errorHandler from '@/common/errorHandler.js'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        username: null,
        password: null
      },

      registration: {
        name: null,
        email: null,
        password: null
      }
    }
  },

  methods: {
    async login () {
      if (this.$v.form.$invalid) return
      const loading = this.$loading()
      try {
        await this.$store.dispatch('auth/login', this.form)
        this.$router.push({ name: 'Home' })
        this.$message.success('Signed in successfully.')
      } catch (error) {
        this.form.password = null
        this.$message.error('Wrong email/password combination.')
      } finally {
        loading.close()
      }
    },

    async register () {
      if (this.$v.registration.$invalid) return
      const loading = this.$loading()
      try {
        const user = await this.$store.dispatch('auth/register', this.registration)
        this.$message.success('Signed up successfully. Please log in to continue.')
        this.clearReg()
        this.form.username = user.email
        this.form.password = null
        this.$refs.password.focus()
      } catch (error) {
        errorHandler(error)
      } finally {
        loading.close()
      }
    },

    clearReg () {
      this.registration.name = null
      this.registration.email = null
      this.registration.password = null
    }
  },

  validations: {
    form: {
      username: { required },
      password: { required }
    },

    registration: {
      name: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) }
    }
  }
}
</script>

<style scoped>
  .page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
