<template>
  <div class="home width">
    <heading />

    <el-row
       v-if="categories.length"
      :gutter="20">
      <el-col
        v-for="(category, index) in categories"
        :key="index"
        :span="6">
        <category
          :category="category"
          @add="openAddExpense(category)" />
      </el-col>
    </el-row>

    <manage-expense
      v-if="manageExpense"
      :category="manageExpense"
      @close="manageExpense = null" />
  </div>
</template>

<script>
import store from '@/store'
import Heading from '@/components/Heading'
import Category from '@/components/Category'
import ManageExpense from '@/components/ManageExpense'

export default {
  async beforeRouteEnter (to, from, next) {
    try {
      await store.dispatch('categories/load')
      next()
    } catch {
      next({ name: 'Auth' })
    }
  },

  components: {
    Heading,
    Category,
    ManageExpense
  },

  data () {
    return {
      manageExpense: null
    }
  },

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    categories () {
      return this.$store.getters['categories/list'].filter(category => parseInt(category.created_by) === parseInt(this.user.id))
    }
  },

  methods: {
    openAddExpense (category) {
      this.manageExpense = category
    }
  }
}
</script>

<style scoped>
.home {
  margin: 50px auto;
}
</style>
