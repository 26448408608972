import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import loggedIn from '../common/loggedIn'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    const response = await loggedIn()
    response ? next() : next({ name: 'Auth' })
  } else {
    next()
  }
})

export default router
