import axios from 'axios'

const baseURL = (process.env.NODE_ENV === 'development') ? 'http://expenses.local' : 'https://api.expenses.infinity-digital.rs'
const baseURLAPI = baseURL + '/api'

axios.defaults.baseURL = baseURLAPI
axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

const token = localStorage.getItem('token')
if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`

const data = {
  baseURL: baseURL,
  baseURLAPI: baseURLAPI
}

const config = {
  data,
  install (Vue, options) {
    Vue.prototype.$config = data
  }
}

export default config
