import Vue from 'vue'
import axios from 'axios'
import errorHandler from '@/common/errorHandler.js'

export default (moduleName, url, store, relations = null) => {
  const state = {
    data: {}
  }

  const getters = {
    find: state => id => {
      const data = JSON.parse(JSON.stringify(state.data[id]))
      if (store && relations) {
        Object.keys(relations).forEach(key => {
          const module = relations[key]
          data[key] = Array.isArray(data[key])
            ? data[key].map(id => store.getters[`${module}/find`](id))
            : store.getters[`${module}/find`](data[key])
        })
      }
      return data
    },
    list: (state, getters) => Object.keys(state.data).map(id => getters.find(id))
  }

  const actions = {
    async load ({ commit }, params = null) {
      try {
        const response = await axios.get(url, params)
        response.data.data.forEach(item => commit('add', item))
        return Promise.resolve(response.data.data)
      } catch (error) {
        errorHandler(error)
        return Promise.reject(error)
      }
    },

    async get ({ commit }, id) {
      try {
        const response = await axios.get(`${url}/${id}`)
        commit('add', response.data.data)
        return Promise.resolve(response.data.data)
      } catch (error) {
        errorHandler(error)
        return Promise.reject(error)
      }
    },

    async create ({ commit }, payload) {
      try {
        const response = await axios.post(url, payload)
        commit('add', response.data.data)
        return Promise.resolve(response.data.data)
      } catch (error) {
        errorHandler(error)
        return Promise.reject(error)
      }
    },

    async update ({ commit }, { id, payload }) {
      try {
        const response = await axios.update(`${url}/${id}`, payload)
        commit('add', response.data.data)
        return Promise.resolve(response.data.data)
      } catch (error) {
        errorHandler(error)
        return Promise.reject(error)
      }
    },

    async delete ({ commit }, id) {
      try {
        await axios.delete(`${url}/${id}`)
        return commit('delete', id)
      } catch (error) {
        errorHandler(error)
        return Promise.reject(error)
      }
    }
  }

  const mutations = {
    add (state, data) {
      if (store && relations) {
        Object.keys(relations).forEach(key => {
          if (data[key]) {
            const relData = data[key]?.data || data[key]
            data[key] = Array.isArray(relData) ? relData.map(item => item.id) : relData.id
            const module = relations[key]
            const items = Array.isArray(relData) ? relData : [relData]
            items.forEach(item => store.commit(`${module}/add`, item))
          }
        })
      }
      Vue.set(state.data, data.id, data)
    },

    delete (state, id) {
      Vue.delete(state.data, id)
    }
  }

  store.registerModule(moduleName, {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
  })
}
