<template>
  <el-card class="category">
    <el-button
      type="success"
      icon="el-icon-plus"
      class="add-expense"
      circle
      @click="$emit('add')" />

    <h2>{{ category.name }}</h2>
    <div class="icon">
      <ion-icon :name="category.icon" size="large" />
    </div>
    <span class="expense">{{ expense | money }}</span>
  </el-card>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },

  computed: {
    expenses () {
      const expenses = this.$store.getters['expenses/list'] || []
      return expenses.filter(expense => parseInt(expense.category_id) === parseInt(this.category.id))
    },

    expense () {
      return this.expenses.reduce((acc, expense) => acc + expense.expense, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  text-align: center;
  position: relative;
  overflow: initial;
  margin: 10px 0;
  h2 {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
  }
  .icon {
    width: 50px;
    height: 50px;
    margin: 15px auto;
    border-radius: 9999px;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .expense {
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    i {
      font-style: normal;
      font-size: 15px;
    }
  }
  .add-expense {
    transition: all ease .2s;
    position: absolute;
    opacity: 0;
    top: -15px;
    right: -15px;
    z-index: 10;
  }
  &:hover .add-expense {
    opacity: 1;
  }
}
</style>
